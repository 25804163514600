<template>
  <div class="errorP">

    <div class="errorP-inner">
      <h1><img src="@/assets/img/sub/notice.png" alt="notice"></h1>
      <p>
        익스플로러(IE) 브라우저에서 이용이 불가능한 서비스입니다.<br>
        다른 브라우저를 이용해주세요.
      </p>
      <div>
        <a @click="$router.go(-1)" class="btn color-1 btn-large w-200 btn-round100 mr-10">이전 페이지 이동</a>
        <a href="https://www.google.co.kr/chrome" target="_blank" rel="noopener noreferrer" class="btn color-6 btn-outline btn-large w-200 btn-round100">Chrome 다운로드 </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
